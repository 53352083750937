<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>
          <ion-item class="ion-margin-bottom" lines="none">
            <ion-label position="floating">Complete Chapter before</ion-label>
            <ion-datetime
              type="text"
              v-model="date"
              placeholder="Select Date"
            ></ion-datetime>
            <ion-button fill="solid" slot="end" @click="saveCompleteBeforeDate">
              Save
            </ion-button>
          </ion-item>
        </ion-col>

        <ion-col size="12">
          <list-chapter-progression
            :resources="resources"
            :description="description"
          ></list-chapter-progression>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ListChapterProgression from "../../Components/Academic/Subject/ListChapterProgression.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonDatetime,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, maxLength, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["chapter", "description"],
  components: {
    BaseLayout,
    // BaseModal,
    ListChapterProgression,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
    IonDatetime,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "Chapter Progression",
      date: null,
      chapter_id: this.chapter,

      backLink: "academic",
      reloadable: true,

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },

    chapter() {
      this.fetch();
    },
  },

  computed: {
    resources() {
      return this.$store.getters["chapterProgression/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 ccharacters",
          maxLength(255)
        ),
      },
      chapter_id: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("chapterProgression/resources", {
        refresh: refresh,
        resourceId: this.chapter,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("chapterProgression/resources", {
        refresh: true,
        resourceId: this.chapter,
      });
      if (event) {
        event.target.complete();
      }
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async saveCompleteBeforeDate() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        chapter_id: this.chapter_id,
        complete_before: this.date,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("chapterProgression/createResource", data);
        spinner.dismiss();
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
        this.password = null;
        this.validationError = null;
      }
    },

    initDate() {
      const data = this.resources.data;
      if (!Array.isArray(data) || data.length < 1) {
        return;
      }

      const progressions = data[0].chapter_progressions;
      if (!Array.isArray(progressions) || progressions.length < 1) {
        return;
      }

      const first_date = progressions[0].complete_before;

      this.date = first_date;
    },
  },

  async created() {
    await this.fetch();
    this.initDate();
  },

  updated() {
    this.initDate();
  },
};
</script>

<style scoped>
</style>
