<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header>
      <h6>{{ description }}</h6>
    </ion-list-header>
    <ion-list>
      <ion-item
        lines="none"
        v-for="item in resources.data"
        :key="item.id"
        :ref="`items-${item.id}`"
      >
        <ion-card class="full-width">
          <ion-item lines="none" class="ion-no-padding">
            <ion-card-header>
              <ion-card-title>Section: {{ item.name }} </ion-card-title>
            </ion-card-header>
          </ion-item>
          <ion-card-content>
            Started by:
            <p>{{ startedText(item) }}</p>
            <br />
            Completed by:
            <p>{{ completedText(item) }}</p>
          </ion-card-content>
          <ion-item>
            <ion-button
              fill="solid"
              expand="full"
              :disabled="disableStart(item)"
              @click="updateChapterProgression('start', item)"
              >Start</ion-button
            >
            <ion-button
              fill="solid"
              expand="full"
              slot="end"
              :disabled="disableComplete(item)"
              @click="updateChapterProgression('complete', item)"
              >Complete</ion-button
            >
          </ion-item>
        </ion-card>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No sections found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonItem,
  // IonModal,
  IonButton,
  // IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  loadingController,
  alertController,
} from "@ionic/vue";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    description: {
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {
      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },

    startedText(item) {
      const progression = item.chapter_progressions;
      if (!Array.isArray(progression) || progression.length < 1) {
        return "Not Started.";
      }

      const started_by = progression[0].started_by
        ? progression[0].started_by
        : false;
      if (!started_by) {
        return "Not Started.";
      }

      const date = moment(item.started_at).format("MMM Do YYYY");

      return `${started_by.user.user_detail.name}, ${date}`;
    },

    completedText(item) {
      const progression = item.chapter_progressions;
      if (!Array.isArray(progression) || progression.length < 1) {
        return "Not Completed";
      }

      const completed_by = progression[0].completed_by
        ? progression[0].completed_by
        : false;
      if (!completed_by) {
        return "Not Started.";
      }
      const date = moment(item.completed_at).format("MMM Do YYYY");

      return `${completed_by.user.user_detail.name}, ${date}`;
    },

    disableStart(item) {
      const progression = item.chapter_progressions;

      if (!Array.isArray(progression) || progression.length < 1) {
        return true;
      }

      const started_by = progression[0].started_by
        ? progression[0].started_by
        : false;

      if (started_by) {
        return true;
      }

      return false;
    },

    disableComplete(item) {
      const start_disabled = this.disableStart(item);

      if (!start_disabled) {
        return true;
      }

      const progression = item.chapter_progressions;

      if (!Array.isArray(progression) || progression.length < 1) {
        return true;
      }

      const completed_by = progression[0].completed_by
        ? progression[0].completed_by
        : false;

      if (completed_by) {
        return true;
      }

      return false;
    },

    async updateChapterProgression(status, item) {
      const progression = item.chapter_progressions;
      if (!Array.isArray(progression) || progression.length < 1) {
        return;
      }

      const chapter_id = progression[0].chapter_id;

      let data = {
        id: item.chapter_progressions[0].id,
        status,
        chapter_id,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("chapterProgression/updateResource", data);

        /** Refresh Chapter Progressions */
        await this.$store.dispatch("chapterProgression/resources", {
          refresh: true,
          resourceId: chapter_id,
        });

        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },
  },
};
</script>
